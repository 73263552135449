<span class="arrow">
	<svg id="Arrow" viewBox="0 0 232 232">
		<defs>
			<linearGradient id="arrow-gradient" x1="157.02" y1="90.68" x2="157.02" y2="246.27" gradientUnits="userSpaceOnUse">
				<stop offset="0" stop-color="#eee" />
				<stop offset="1" stop-color="#888" />
			</linearGradient>
		</defs>
		<path data-name="Outer Stroke" d="M66.42,284.52a23.47,23.47,0,0,1-5-.55A22.91,22.91,0,0,1,47,273.77a22,22,0,0,1-2.7-17L66,168.44,44.56,80.21A22.17,22.17,0,0,1,45.84,66a22.82,22.82,0,0,1,20.9-13.45,23.06,23.06,0,0,1,8.56,1.64c20.44,8.05,56.57,23.67,90.92,41.11,38.22,19.41,80.64,43.46,98.4,54.06a22.74,22.74,0,0,1,7.13,6.71,22.27,22.27,0,0,1,3.35,17.17,22.54,22.54,0,0,1-10.3,14.4c-17.12,10.45-61.08,36.68-99.07,54.38l-12.88,6c-32.36,15.09-62.93,29.34-78.37,35.08A23,23,0,0,1,66.42,284.52Z"
		 transform="translate(-43.6 -52.52)" style="fill: #4f5456" />
		<path data-name="Inner Stroke" d="M159,109.53C124.09,91.82,87.87,76.3,69.38,69a7,7,0,0,0-8.92,3.45,6.14,6.14,0,0,0-.36,4L82,166.63a6.3,6.3,0,0,1,0,3.65L59.78,260.6a6.45,6.45,0,0,0,5.08,7.75,7.17,7.17,0,0,0,4-.28c16.89-6.27,55.14-24.33,90.06-40.58,37.65-17.55,81.41-43.73,97.51-53.55a6.24,6.24,0,0,0,2-8.9,6.6,6.6,0,0,0-2.1-2C238.55,152.4,196.37,128.53,159,109.53Z"
		 transform="translate(-43.6 -52.52)" style="fill: #fff" />
		<path d="M97.51,174.36a22.32,22.32,0,0,0,0-11.78L80,90.68c18.69,7.87,45.44,19.78,71.7,33.12,29.6,15,61.74,32.88,82.52,44.84-20.71,12.3-53.29,31-82,44.34l-12.89,6c-21.48,10-43.47,20.27-59.54,27.28Z"
		 transform="translate(-43.6 -52.52)" style="fill: url(#arrow-gradient)" />
	</svg>
</span>




<style>
  .arrow {
    position: absolute;
    left: 50%;
    top: 60%;
    width: 20px;
    height: 20px;
    animation: boing 1.2s ease infinite;
    transform-origin: center;
    pointer-events: none;
  }
  svg {
    position: absolute;
    width: 20px;
    left: 0;
    top: 0;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.4));
  }

  @keyframes boing {
    0%,
    100% {
      transform: rotate(-90deg) translateY(-50%) translateX(-90%) scaleX(1)
        scaleY(1);
    }
    50% {
      transform: rotate(-90deg) translateY(-50%) translateX(-60%) scaleX(0.95)
        scaleY(1.1);
    }
  }
</style>